import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

interface Props {}

const PartnerLogo: React.FC<Props> = () => {
  const data = useStaticQuery(graphql`
    query {
      partnerLogo: file(relativePath: { eq: "partner-logo.png" }) {
        childImageSharp {
          fixed(width: 235, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return <Img fixed={data.partnerLogo.childImageSharp.fixed} />
}

export default PartnerLogo
