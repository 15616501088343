import React from "react"
import {
  Box,
  Flex,
  Heading,
  Center,
  Text,
  Button,
  Stack,
} from "@chakra-ui/core"
import PartnerLogo from "./PartnerLogo"

interface Props {}

const Hero: React.FC<Props> = () => {
  return (
    <Box
      borderBottomWidth={1}
      borderBottomColor="gray.200"
      // backgroundImage="url('/desktop-banner-01@2x.jpg')"
      backgroundPosition="top center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <Center>
        <Stack
          paddingX={{ base: 5, lg: 0 }}
          paddingY={6}
          direction="column"
          maxWidth="700px"
          spacing={6}
          alignItems="center"
          justifyItems="center"
        >
          <Heading as="h1" fontSize="5xl" textAlign="center">
            PRINT PHOTOS AT WALGREENS
          </Heading>

          <Heading as="h2" textAlign="center">
            Give the extraordinary gift of memories.
          </Heading>
          <Box textAlign="center">
            <Text>
              Print photos or create personalized photo cards, canvas prints,
              floating frames, wood panels, photo mugs PrintBooks and more.
            </Text>
            <Text>
              Have them delivered to your door or printed for same day pickup at
              your local Walgreens.
            </Text>
          </Box>
          <Button backgroundColor="primary" color="white">
            Get Started
          </Button>
          <PartnerLogo />
        </Stack>
      </Center>
    </Box>
  )
}

export default Hero
