import React from "react"
import { Box, Flex, Button } from "@chakra-ui/core"
import Wrapper from "./Wrapper"
import Logo from "./Logo"

interface Props {}

const Branding: React.FC<Props> = () => {
  return (
    <Wrapper>
      <Flex justifyContent="space-between" alignItems="center" paddingY={3}>
        <Logo />
        <Button backgroundColor="primary" color="white">
          Get Started
        </Button>
      </Flex>
    </Wrapper>
  )
}

export default Branding
