import React from "react"
import { Box, Flex, Link, Stack } from "@chakra-ui/core"
import Wrapper from "./Wrapper"

interface Props {}

const TopBar: React.FC<Props> = () => {
  return (
    <Box backgroundColor="header" paddingY={2}>
      <Wrapper>
        <Flex color="white" justifyContent="flex-end">
          <Stack direction="row" spacing={6}>
            <Link to="">Stores</Link>
            <Link to="">Gift Guide</Link>
            <Link to="">Help Center</Link>
          </Stack>
        </Flex>
      </Wrapper>
    </Box>
  )
}

export default TopBar
