import React from "react"
import { Box } from "@chakra-ui/core"
import Wrapper from "./Wrapper"
import Hero from "./Hero"

interface Props {}

const Main: React.FC<Props> = ({ children }) => {
  return (
    <Box as="main">
      <Hero />
      <Wrapper>
        main
        {children}
      </Wrapper>
    </Box>
  )
}

export default Main
