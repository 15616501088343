import React from "react"
import { Box } from "@chakra-ui/core"
import Wrapper from "./Wrapper"
interface Props {}

const Footer: React.FC<Props> = () => {
  return (
    <Box as="footer">
      <Wrapper>footer {new Date().getMilliseconds()}</Wrapper>
    </Box>
  )
}

export default Footer
