import React from "react"
import { Box, Flex, Button, Link } from "@chakra-ui/core"
import Wrapper from "./Wrapper"

interface Props {}

const Navigation: React.FC<Props> = () => {
  return (
    <Box as="nav" backgroundColor="gray.50">
      <Wrapper>
        <Flex justifyContent="center">
          <NavigationLink to="/">Prints</NavigationLink>
          <NavigationLink to="/">Greeting Cards</NavigationLink>
          <NavigationLink to="/">Canvas</NavigationLink>
          <NavigationLink to="/">Print Pabels</NavigationLink>
          <NavigationLink to="/">Posters</NavigationLink>
          <NavigationLink to="/">Wall Decor</NavigationLink>
          <NavigationLink to="/">Photo Gifts</NavigationLink>
          <NavigationLink to="/">Designer Prints</NavigationLink>
          <NavigationLink to="/">Photo Mugs</NavigationLink>
        </Flex>
      </Wrapper>
    </Box>
  )
}

interface NavigationLinkProps {
  to: string
}

const NavigationLink: React.FC<NavigationLinkProps> = ({ to, children }) => {
  return (
    <Link
      paddingX={3}
      paddingY={5}
      fontWeight="normal"
      color="gray.700"
      to={to}
    >
      {children}
    </Link>
  )
}

export default Navigation
