import React from "react"
import { Box, Flex } from "@chakra-ui/core"
import Navigation from "./Navigation"
import Wrapper from "./Wrapper"
import TopBar from "./TopBar"
import Branding from "./Branding"

interface Props {}

const Header: React.FC<Props> = () => {
  return (
    <Box as="header">
      <TopBar />
      <Branding />
      <Navigation />
    </Box>
  )
}

export default Header
