import React from "react"
import { Box } from "@chakra-ui/core"

interface Props {}

const Wrapper: React.FC<Props> = ({ children }) => {
  return (
    <Box
      paddingX={{
        base: "35px",
        lg: "80px",
      }}
    >
      {children}
    </Box>
  )
}

export default Wrapper
